class WebSocketClient {
  constructor(reconnectDelay = 1000, maxReconnectAttempts = 10) {
    this.socket = null;
    this.url = 'ws://api.hitcfs.com:2346';
    this.reconnectDelay = reconnectDelay;
    this.maxReconnectAttempts = maxReconnectAttempts;
    this.reconnectAttempts = 0;
  }

  connect() {
    if (this.socket) {
      console.warn('WebSocket is already connected or connecting.');
      return;
    }

    this.socket = new WebSocket(this.url);

    this.socket.onopen = () => {
      console.log('WebSocket connected.');
	      let userInfo = localStorage.getItem('userInfo');
	      if (userInfo) {
	          userInfo = JSON.parse(userInfo);
	          let pingMessage = JSON.stringify({
	              userId: userInfo.uid,
	              customId: userInfo.uid,
	              type: 'init'
	          });
	          this.socket.send(pingMessage);
	      } else {
	          console.error('User info not found in localStorage');
	      }
      this.reconnectAttempts = 0; // 重置重连尝试次数
    };

    this.socket.onmessage = (event) => {
      this.handleMessage(event.data);
    };

    this.socket.onclose = () => {
      console.log('WebSocket disconnected.');
      this.socket = null;

      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        setTimeout(() => {
          this.reconnectAttempts++;
          this.connect();
        }, this.reconnectDelay);
      } else {
        console.warn('Max reconnect attempts reached. Connection closed permanently.');
      }
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  sendMessage(message) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error('WebSocket is not open. Cannot send message.');
    }
  }

  close() {
    if (this.socket) {
      this.socket.close();
    }
  }

  handleMessage(message) {
    console.log('Received message:', message);
    // 在这里添加自定义的消息处理逻辑
  }
}

const ws = new WebSocketClient();
export default ws;