import Vue from 'vue';
import VueI18n from 'vue-i18n';

// 导入语言文件
import en from './locales/en.json';
import zh from './locales/zh.json';
import id from './locales/id.json';
import pt from './locales/pt.json';
// 使用 VueI18n 插件
Vue.use(VueI18n);

// 创建 i18n 实例
const i18n = new VueI18n({
  locale: 'en', // 默认语言
  messages: {
    en: en,
    zh: zh,
	id: id,
	pt: pt,
  }
});

export default i18n;
