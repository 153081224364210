<template>

	<div class="container">
	  <van-icon class="top-right-button" name="setting-o" size="25" color="#fff" @click="showPicker = true"/>
	
	
  <div class="form">
    <div v-if="infoType == 0" class="login_form">
      <van-form autocomplete="off" >
        <div class="title">{{ $t('login.login') }}</div>
        <van-field
          v-model="loginInfo.name"
          name="name"
          left-icon="/static/image/user.png"
          clearable
          :placeholder="$t('login.qsryhm')"
        ></van-field>
        <van-field
          v-model="loginInfo.password"
          name="password"
          left-icon="/static/image/password.png"
          clearable
          :placeholder="$t('login.qsrmm')"
          type="password"
        ></van-field>
        <div class="cell">
          <van-button
            block
            round
            color="#cf866b"
            :text="$t('login.login')"
            native-type="submit"
			@click="login"
          />
        </div>
		<div class="cell">
		  <van-button
		    block
		    round
		    color="#00aaff"
		    :text="$t('login.register')"
		    @click="changInfoType(1)"
		  />
		</div>
        <div class="bottom">
          <span @click="$parent.goNav('/home')">{{ $t('login.xqgg') }}</span>
          <span @click="window.location.href = 'https://www.baidu.com'">{{ $t('login.online') }}</span>
        </div>
      </van-form>
<!--      <div class="locationToRight" @click="changInfoType(1)">
        <img src="/static/image/account.png" alt="" />
		<div v-if="this.$i18n.locale == 'zh'">
			<div>{{ $t('login.zhu') }}</div>
			<div>{{ $t('login.ce') }}</div>
			<div>{{ $t('login.xin') }}</div>
			<div>{{ $t('login.yong') }}</div>
			<div>{{ $t('login.hu') }}</div>
		</div>

      </div> -->
    </div>
    <div v-if="infoType == 1" class="register_form">
<!--      <div class="locationToRight" @click="changInfoType(0)">
        <img src="/static/image/userregister.png" alt="" />
		<div v-if="this.$i18n.locale == 'zh'">
			<div>{{ $t('login.fan') }}</div>
			<div>{{ $t('login.hui') }}</div>
			<div>{{ $t('login.deng') }}</div>
			<div>{{ $t('login.lu') }}</div>
		</div>

      </div> -->
      <van-form
        autocomplete="off"
        @submit="register"
        style="background-color: rgba(0, 0, 0, 0.7);border-radius: 0;"
      >
        <div class="title">{{ $t('login.register') }}</div>
        <van-field
          v-model="registerInfo.name"
          :placeholder="$t('login.qsryhm')"
          name="name"
          left-icon="/static/image/user.png"
        ></van-field>
        <van-field
          v-model="registerInfo.password"
          :placeholder="$t('login.qsrdlmm')"
          name="password"
          type="password"
          left-icon="/static/image/password.png"
        ></van-field>
        <van-field
          v-model="registerInfo.confirmPass"
          :placeholder="$t('login.qsrqrmm')"
          name="confirmPass"
          type="password"
          left-icon="/static/image/password.png"
        ></van-field>
        <van-field
          v-model="registerInfo.realname"
          :placeholder="$t('login.qsrzsxm')"
          name="realname"
          left-icon="/static/image/username.png"
        ></van-field>
        <van-field
          v-model="registerInfo.paypassword"
          :placeholder="$t('login.qsrzfmm')"
          name="paypassword"
          type="password"
          left-icon="/static/image/code.png"
        ></van-field>
			<van-field
			  readonly
			  clickable
			  :value="Contury"
			  :placeholder="$t('login.xzgj')"
			  @click="showConturyPicker = true"
			/>
			<van-popup v-model="showConturyPicker" round position="bottom">
			  <van-picker
				show-toolbar
				:columns="conturyColumns"
				@cancel="showConturyPicker = false"
				@confirm="onConturyConfirm"
				confirm-button-text="confirm"
				cancel-button-text="cancel"
			  />
			</van-popup>
        <!-- <van-field
              v-model="registerInfo.code"
              placeholder="请输入验证码"
              name="code"
            >
              <template #right-icon>
                <img
                  style="cursor: pointer; height: 20px"
                  :src="`/static/image/yzm/${imgLis[index]}.png`"
                  @click="changIndex"
                  alt=""
                />
              </template>
            </van-field> -->
        <div class="cell">
          <van-button
            block
            round
            color="#cf866b"
            :text="$t('login.register')"
            native-type="submit"
          />
        </div>
		<div class="cell">
		  <van-button
		    block
		    round
		    color="#00aaff"
		    :text="$t('login.login')"
		    @click="(0)"
		  />
		</div>
      </van-form>
    </div>
    <div
      style="display: none"
      id="app-footer"
      class="footer_footer_bg_hidden__1QCjG"
    >
      <ul>
        <li class="" data-analytics="button" :data-label="$t('login.home')">
          <i></i>
          <div
            style="cursor: inherit"
            class="image_imageContainer__2dKjX image_cover__3B5D- footer_icon__1Rh5j"
          >
            <div
              style="
                display: block;
                overflow: hidden;
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                box-sizing: border-box;
                margin: 0px;
              "
            >
              <img
                class="footer_icon__1Rh5j"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEVklEQVRoge2ZT2zbVBzHAxoIEBxgAk0CIY4gxAWkSQghgcQFBDshIXFjlzEIF24goQmhHcoJoYnTOHBaWlg1mO2kSZv37KSxn5NtbVBI/ziJnffshGRts7GtZRR+yKUJiZtgu423TuQnfQ+R8/Q+3zy/9/vaCYVGNapRDbUKBbgXE/YxJuw8JqyKFbqCFYYwoV/OztcfC+3nQln6CiJsASkU+gkTuoZVdhwA7grttxIJO4YJ+3MQfK8R9h0COBDaL4VV9pkX8G4lZcrnctYDtxUcAO5GKj3lF356lgKHDeCxLk8TdvB2btZxJ5w6X4elyhpIKusPn64Ch/R/hfViIm09eUvhUaHxIFZYwgk3V2zA7zc3wa7FyuoO+IQTHrVlsFjaePaWwEsXa48iheWccPmFJvx2/Sasb/yxJWWu1nM9nhoEr2+vhLEqSMZLgcKjS7WnsMKWnPA/LzZhpbUOrasbsHZlA4gDfsoNHnVM3IiK1SOBwIu5+nOYUKsfvNW4Bo2V61BrXIPZC2bP9ZhXeNS5nTYFsXp0qPCYWC/bTcgJP7/QhAq9ArR2Fcq0BVK2d+NGJcMnvN6RgKufDgVezJpHsELXexqRQuFi4VdYKK+CZqxBUVuxu2wvvLh7eK5jwvh6T11bVMyjSGGbTvhsvg7zxSYUli7D3C+N3tNGHg48ty0eGRH7yPYNj1Xzg37nuH2Pq/n61gqo872bNSlTEPBwwLneXsEj5CN6YIW9OijXpHImyJesHZt1RqbAiwHAIx3OJ3WI8NpXPgzQ/H+Hsd7PMxlqx4JA4H9KVuAMvwwRTtv8YarytCt8SrFe8JVr5Kq9xIHBR7hlOLOtcU476f7rE/Mjz/CZ4ODPzVQ64B3xy9iDAXbSW6IMEH66vBOeW7b3waIHA3TMDT4xawQGPxkv9YXfVmnPBuI2fADgHHaF37uBeCo4+LNTrvB7MzAVFDzS4fuoJ/jdG4h5CWVYN+zswmPjEzdxWP+GQ3r9H3jNK/zuDMQk91+QR0Y6nW4+FPJRsQx9ZELQ8j7g/RlI+ojDMYkebo/PULgfZc3XkMLe7padajNzlx/vnms8WnojEANJuQqCj1yTy8E9ncdNQrWBEUSh61g132rPNcFXDg3fgEzHBJ9xuD1WJOYJtx5i56z29yen2cGhGxAkY8zvKdIeiwg97drFCW0FaoDHIwMwWgFudAvp+3ITn42X79xNPBkvAVaqd6aBc4ny1usaTKinPvD5fjLw43Sl+2VCwdUAlunrvA94Huu19lhRYV+4d2JW7BhGcCDCaTcGwdsP9Q7zp0NeKpmh33p5ZBRE46+ZDA13gBT2BCa0NtgA2xSJ9W73XON86UQ/eA454dkCUhuHQl5rJkMPR0XjmID0cD/xUvV9KWc97xyXyrceFlXzHftPv25h1XxPvGA902+uiejyixGhdDwiaOEJQQvHJD2MiLklUTE/RIS9iSpwn2f4UY3qf1R/A/1BRmX2Ea0WAAAAAElFTkSuQmCC"
                decoding="async"
                style="
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  bottom: 0px;
                  right: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                  object-fit: cover;
                "
              />
            </div>
          </div>
          <span class="">{{ $t('login.home') }}</span>
        </li>
        <li class="" data-analytics="button" :data-label="$t('login.discount')">
          <i></i>
          <div
            style="cursor: inherit"
            class="image_imageContainer__2dKjX image_cover__3B5D- footer_icon__1Rh5j"
          >
            <div
              style="
                display: block;
                overflow: hidden;
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                box-sizing: border-box;
                margin: 0px;
              "
            >
              <img
                class="footer_icon__1Rh5j"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEUElEQVRoge2ZTWwbRRiGDRTooUJAhUBISIgT4lCERLjCCfGjnkAcEIgLEj30hoRQhUAcoBIXuJBL4YCEZOMEldLZdVLbmVm7dnbWTtsEWYmwY+/uzHpdJ2liuQ0OpHxoNzGEFLx/Y35Uf9J7sOSdfZ7RzOzndSw2qlGNyrMA4BaisQ8JZU1CeZtQ/hXR7LGYgCpWOvcSar2DKV8ilHUIZal86fIjMZGFNf4eVhnsD6F8VqHNV8tluD3omLk5doRQdoqobPPGcVkdAxwQJuDM/F8J7LlhU6HWB1hrPzBoHAC4LVdiL2HKyKDxsrMMZGIcFQKPK+1Dg262T2QLU/Z1rmQ/tXcMSvlhrLF3CeWm1xjZIgOJ6CAr5ttCBFwJlXG/EntkTrnXltgzROVdP9dkCyZIWAfkJKe/KExA0fgrWOXbwST4Nm7AQULZl36+nykwQMRw4SWip5yDQ5iAK1Gyntg5ifi4r6j8dec6XG4+qmj800HfTRfMcUT0cQkbnyFsvObsFaHw/9sCgFsrFbjj30o5xPHsFtaaz2GVlwllPwfdvH4znd9Z78gjEjGYRIxPMIaDvuAVtfnssKD7mfIJj/aKYGPSl4DzdB0qfC44PNpNKseOeC8fynpDgZ81IZULB476AorxhrfAEOBnVAZyhJlH/WWkGG/94wIzTl+jRANHQQQI5UwoPBEDj9wlxF7wFlD55yLgnY5SEgiPsLGK8frdngKqunYXobwQCb5oCoWXsL4hE/Z8LMgTmGjWUaJa7yuqdTJIskV2UlIMMSHGxxLW3zx33nwwdlNXfmHjntwF+z6vyDlxKYftg/rltLSE8hNY5S2vNZ8+bwrcrHp/3fcQ0aXTuPFwKAFC+Ud+Nuy5IcCjHQGYnFqGhFRrJHH7UCD4ahXudH/f7kLSeRt4qwut1WvA7S40eAd+bKzDwtIq0PkW5EtNkJXoT1rUD9FhYmoZ4qjq5htp+VgggTzlj/fhSwstuH79V+htbUOnuwXttU1XxhG4tLgC6qUWEGpBSkCrgHZnfiJV+x3eSQJVx4MtH80e6wu0Vq7BZu8XWNv4CWrGOizW1mCu0obiBRuIZkG6IK5VQFiH5D54V0CqfhFaYOXKJqx3ei648zklcqngffDSn8GFCFiXu2C3r8LC4spQ4RN/Ax9ZoME6booX7SHBNyA+AD6ywNLyFZhfWnXegwqHP4t1T/hIAs5rvtIPbZi92IJ0Qex5f3am4ZwunvChBdyOEuugaBbMqFxod/l91j98PJRAwR5zHibOzcT29DqcydR9g8fDCqSIOTaMDftdOjh8/L8icDr9R2swdAEJm08Kgyc6fDsdHj4eRiBD+WFR8JMR4eNO5OqJWNBC2MhEFZhIRYdPoOpWUjYeCywwrVgPIWLMhZ35pHxjUxY4UvVqXKq6/zOEKozhgJxjT0vYPCZj/bjfJKfqxxNyLVKScv3lM5n6/aHhRzWqm6R+Az1Dmn8oNv4FAAAAAElFTkSuQmCC"
                decoding="async"
                style="
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  bottom: 0px;
                  right: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                  object-fit: cover;
                "
              />
            </div>
          </div>
          <span class="">{{ $t('login.discount') }}</span>
        </li>
        <li class="" data-analytics="button" :data-label="$t('login.customer')">
          <i></i>
          <div
            style="cursor: inherit"
            class="image_imageContainer__2dKjX image_cover__3B5D- footer_icon__1Rh5j"
          >
            <div
              style="
                display: block;
                overflow: hidden;
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                box-sizing: border-box;
                margin: 0px;
              "
            >
              <img
                class="footer_icon__1Rh5j"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE/UlEQVRoge2ZXWgcVRTHt1rrByr1wRfRgoKQB1+0UuyDHw/Wl2JFiyhWBYUiKFZqDBTUJ8UXkT5aBEGpOJM11obeO/uV5N7ZbDYzm9UQajB17u7OzJ3dtGnzXVONTY7cbdJusjP7kcykBfOHw8IOM/z+55577pndUGhTm9qUr1L1sw+qmeIrVHcOqxn+Jc04X1C9+L6a4S8lfxu9N3QjigxObqcZ51OqOUNE4+AVVHcWqOb0qlrxPcOAW683dyibhVvUjNNKNT5eC9zVjOYUSD8/cN3gRTlQnSe1wVGwitNwfmIOiudm4Ux+ArKnz9Y10NXHARETMLWOEwK3bSi8OsgfFhm0SzOwsLgIF+fmoTR2EUbyE5AZqg+f6LPL8GgpMDE1Qia3b1y9687Ihck5EJq5+A84o7Mw9McYpLLFuvDxVfBo2QS14gBwc/AGdB61ijNl+OnZv+HPwiQkM05DdR9PWa7w6KoJ86tA4anuvKwNluDywiJMTgv4iYY3bay3NjwSQa2FGLEfCazjUI0beXsKxqcugfhUG8x8LNkAPFneDxYKxEBygO8XMKJ8cvYUDDTQaUREm4BHSxEjpRbfDVDNOS6ARKf59fdz9eH7bYgkmwNH11bhiK/wojtQjU8IsP7BUl34Ho2DsobMo6t7wUz7aoBozv2Nbtaefg6KukZwcvVcmPLVQHJgdFfD8HR98GgpstnSHU2DipvEFEl05xjN8IMEYKv4Xh0o7qsH393PRR/3BR6J6LUeKq8+GbsTEfOjnyLsWLuSexsAbnKFHx4e3kZ1fnoFmM6jALBluQN5wqdtf+HJlU4k4DExR8LYAAktB+t0L5PM6NOuU+NAcU8tA10C3kdwVGEAU/uwjFkF/JVox/mnqgyoeuk1VwO686GXgUTaFqen7/CobKDQIinGt6vhRcgo926VAaKVXnc3UGxzM7B6ovQzThETOmKFFhmz790MtGN2aF0GEqkA4btNkJERnIF4gPCd3fkyvBSUgVhvgPBd+RWQvhuIBgh/MrES3ncD0T6+Pyj4E4lcFaCvBiJJ3hZR7X2+w1MTTsTd4f0x0M8holrihG2LJvkuv+E7Yt7w0toMFF91mygxtQ4lUqUdfhroiNaGl5AB4URuh4TYN64HGWbvuBhwnijDpzlg9drpGqHObkJgK6LmjB+ZD0eqRwOpGnAxPAzb2hV20PV61HisyoCY8nrSPIorRgOsWl8vX8fU/NkLDFNLRUlzb62IJs294WhubxhdiXbMPvE2YIyVk0pgq4xZz8oxwjhaBV9hYotCrecV1W6NEP5M5TVM7be8s2s5oSYlYfaZ9yqwE5WJlbHxQrvCWqVo/snQWpVKnb8LEeuC5yoQ+/FGnyUSJWE25LkCCnszFIRER/IuI3Monea3N/IcGeeOeGYfG9MIWfcEYkAAYmoWapTSD/V+qBXlICN22TP7iH0cClKo19qJqXnJu9NYBqbWntX3dcbt+yTMfqzZfRCzTq3lXbhZ1drQnd2F5VLgMmaK2JAyYlkJsYU68H91KOajgcNfM2G9gak5Xwl/smsJvtnAxnw4wl4MbbQQsZ7F1BwV8L+4TJSNhIyNsXW1x/VK0cbv7kwUjsrY+Lc5cLYoIyZJMfZA6EaQmF1EB5ExO1MH/oKE2HdhxHaGblSJrMpK/jkZ5z6QFfa5jFmbrOQOhCPG7vBG/AuzqU39j/QfAdrRLU83fWwAAAAASUVORK5CYII="
                decoding="async"
                style="
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  bottom: 0px;
                  right: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                  object-fit: cover;
                "
              />
            </div>
          </div>
          <span class="">{{ $t('login.customer') }}</span>
        </li>
        <li class="" data-analytics="button" :data-label="$t('login.sponsor')">
          <i></i>
          <div
            style="cursor: inherit"
            class="image_imageContainer__2dKjX image_cover__3B5D- footer_icon__1Rh5j"
          >
            <div
              style="
                display: block;
                overflow: hidden;
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                box-sizing: border-box;
                margin: 0px;
              "
            >
              <img
                class="footer_icon__1Rh5j"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAESElEQVRoge2YT2gjVRjAo4J7EPGkIq4HEQQPihfBVe8qiIqeBA+exJPoxbMIiuJJWQQvenClaberrM6bSZqm75tp08wkbbe1pstu0mbevEmTNO12i+2mNm0+maFLp900mTeTtCvkg4+Qf+/9fm++mfnmRSL96Ec/2sa4YT8Nhv0l6PZlanDoeaY5yKopE2DfKWCfiwQNRLwPjNJn1ODbVOd4Ejk2yZGoDCVqukmANSUwf4jPVR4QFlAz/NuTAqc6x6QDDwfw3iTA4oLwpZfAsPeOTgIGx+xfFcxdX8X5azWcXVjBqfkKpmeWQ8JbSFqAH0qVfehbwK33I5MsFNZwp7GH2//u4vrGNpZXNrHIN9zPZ3JV1DJ2MPgURwk6wLtHwSz6FzDsmncS095AJ+rbDbyxUcfllU1csm7iQn4Np/+uYmq6FAh+NGX5gr+dMc18zK9A8/YkDmCz2cRb9QaWqptozJbdUgpb84kJMXjJOQoqf9aXgHciB3qr3sDq6lbgMrkTvvXJKnVIRePPCQvU1m7h6nrdLZduwI+MB4OXggpUalvIy/+4pSQKC4a9BQafp7q967yPh4CXggo4J/D14jrqs2UxeN2uqpnSE+54hv1GbNzaDQMvBRW4uriGc1drODEldqWBDP/YO6ZE2YVTEZjJVVG/Irj6hj2Zz+MZ75gytc8SMMsnLqBlxa48oNs0NVd5pNW4ClgvEDAXTlSAdlxtvgO6/RM17I/U6eVn/Iwva+xlmbJPCLBBt2E7TQEty9+NhAgC7JtTFVCNypNhBCTK3vcrMKwsdV+AZvj5oPByHs8QYFk/8IOk0CMBpynTra+CPDBJlMU6wxcxSvI4IOV7I+B0lDKwpkLZKyICBKxPO8H/SU2MkoIL3xOBhNMO709GgCX9wiPivYSaN9vCjxUxug/eE4ERpx32TEioafoVGNWrj7aD/2OseAi86wIj44fh949A1q9ALof3E2CNVvCXk63huyZwfEfJLkQEgoB5TQQ+vECaY0xr0w4DS3jHSE7yxwm1PpAo+1pS+TuJqRsPec8BCVjJ+//fE0tt4UMJjOkcY579mlbptASEss8VzXpVAvbj0RJxT1qwvlBU600C5q/e734b6QwfXCDNUVGDd5Cd8lJ80Rd8IAFn5eUOKx8mh2P+4YUFxtLuDao38GDixdjBDWqg2wLJtHXsNl83ckgRW/kBUQEiuF/jN53tw0FZfOUHRAV6UzYHTdn/SwDYnvN6tK+5qwUImDMErPdkrfzwxETtwYty4fWoVIjd9QLEAdest44bNyoXXwwjMtwrAQdcptbbvgYPITLsWwBYzSf4FRHw0CLxgrvT1zEImJf8gCPiPZEuxJCSPxcl+Xg7+ChZ9P28EUmmrKckMNd7De5XJCrnd0Yn+WsRkaC6fZZQ67wETCPAfnG6yF6BtxSRC99HpYI2pBR+pin7+Tt+1I9+9CPixH88983PRtjROwAAAABJRU5ErkJggg=="
                decoding="async"
                style="
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  bottom: 0px;
                  right: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                  object-fit: cover;
                "
              />
            </div>
          </div>
          <span class="">{{ $t('login.sponsor') }}</span>
        </li>
        <li class="" data-analytics="button" :data-label="$t('login.my')">
          <i></i>
          <div
            style="cursor: inherit"
            class="image_imageContainer__2dKjX image_cover__3B5D- footer_icon__1Rh5j"
          >
            <div
              style="
                display: block;
                overflow: hidden;
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                box-sizing: border-box;
                margin: 0px;
              "
            >
              <img
                class="footer_icon__1Rh5j"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEPUlEQVRoge2XT2gcVRzHp2gVKoIUBFvBgxdBvXhSg3gRKRVET4IKepOKCJ48eVAhCIIoEk+CByPsJE2KJe/NbppN3pvtZrOTTFMLbdXMbPa9nbfbNdsmsUla3dL85M0mm7HMrrM7O7MV8oPvZfYPn+/v/X6/9xtF2Y/92I+eRcasHdHz4j1qON+SvBijhpOkefEDzYvP9fnKiwBwj3I3BjXE89QQM9QQ2yTvQCtRQ9Sm58Qnplk5pNwNYZqVQzQvhttBezWdcwBTBpjySjLDX+orfHaxdpQaYjE4fAkwYYB2hCm/pdHS+/3LvA/84sU/QFQ3YPNGHeq3bsPGVh3OLghIzzqAKG/Ce4V1/kbsBojh/OQFl5DlHfA7lTXLvuBNUb6lZfiTscHr8+UBL/zsuTJUa1uwun7TVW31BhRK63Bp6SrkzlXaw5PdcmJabAaI4dC9qeIAE3/CldqmK4utQWZBuJ+dOVsKBI92lCT8hbgatzkqz19eAV6+7urCb7XmqUx2CI8ap/Bd5AaIIU54y+f35VVYKq7BZeta81mqC3jkiovoDcw7Q7ugsjkvLl11JftAPktm/CdNUBGy9lCkBqjhnPI2rxybUi68Hg4eEQaRTyOaF1Pe0Tl3vuJOml7AI2mAiOeiNWCI0TsvL42GB0e7kyhbfiJqA4PN1WDOkbdoz+ARZX9rFtwfrYEF5/jeUvZveI3yV00TDv6XsM6/aGEgp0Qdcp+fmSstI5+ywZR/GeQ/MOWzvvWv83eUOAJl+IctMrg2la081u63GnWOY8q3/e4A04SDsRiYvFB9ABFW9b1NCctjsvKIL7xefgZTtuI/ffhHSpyBdDaACfvL/yT4qkb5x/I70znnUUz5y0jnX8v939e0zsYB4IASd2DdeSvs5ME6Nyf6+Xqp6extWfvdjU1+elS78rDS72iUCUsGzjph60jn7/YFFgAOJDOlY5jwbzDl84jyMqbsJqasHjjzM/y2iu16AlubKrK5iuy0qlmfqZP205HCyzmNKC+EqfkJUgQVWZBoIRXZmTFcfLan4Nls7UFEeDpsw07MFFuCJ7wmsL0tT6Qn8HI/l7M9LPzp6WDwCa9wYSjUeE3lnMOIcDM0fLoLeLRrwhru2gAmfCws/M9nlruHRw2N4MKJjuGTuvNKWPhTU+HhE25jW9dHU87hzrJPuR4Gfnyy0BP4xF4pfRoYPpXlT4WBP5nqMTySsquEwL2BDCDKBruGT9oRwFuNXtDs14OWz0I38CPJKDJvecqoMBTwBPhG5/DRZT7RbGabBC2hXzuCx9HDJxo6GbSEvg8GXwQVxwIObg9g64NABlIZdgRTfq0d/ARhcrTFBq8i65fRS3BfIANuGelsoNXmKZeydhtlz+GxnR1P88eVbl7eMS29iSn7ClM+LIUIG5b7SdRSsf3jCLIHVWy91pf35f3YD+X/E/8AJUVb1128F3IAAAAASUVORK5CYII="
                decoding="async"
                style="
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  bottom: 0px;
                  right: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                  object-fit: cover;
                "
              />
            </div>
          </div>
          <span class="">{{ $t('login.my') }}</span>
        </li>
      </ul>
    </div>
  </div>

  <van-popup v-model:show="showPicker" round position="bottom">
	  <van-picker
			:v-model = "selectedValue"	
			:show-toolbar="true"
	  	    :columns="columns"
	  	    @cancel="showPicker = false"
	  	    @confirm="onConfirm"
			confirm-button-text="confirm"
			cancel-button-text="cancel"


	  />
  </van-popup>

</div>
</template>
<script>
import { showToast } from 'vant';
import { Toast } from 'vant'
export default {
  name: "login",
  data() {
    return {
      registerInfo: {},
      loginInfo: {},
      imgLis: [
        "2PYL",
        "6AQ5",
        "8PHD",
        "21I7",
        "69HM",
        "ACWA",
        "DUZ7",
        "IY98",
        "K647",
        "M52T",
        "NY52",
        "NZFA",
        "SN76",
        "SP4D",
        "VAEO",
        "YFQM",
        "ZZU5",
        "7GQT",
        "LFW3",
        "NU2T",
        "UAE3",
      ],
      index: 0,
      infoType: 0, //0 是登陆 1是注册
      psw1: true,
      psw2: true,
      psw3: true,
      pid: "",
      show: false,
	  isFirstClick: true,
      window,
	  popShow:false,
	  showPicker:false,
	  Contury:'',
	  showConturyPicker:false,
	  selectedOption: '',  // 用于绑定选中的选项
	  selectedValue:'en',
	  conturyColumns:[
	        { text: 'Nigéria', value: 'NGA' },
	        { text: 'Brasil', value: 'BRA' },
	        { text: 'Indonesia', value: 'IDN' },
	  ],
	  columns:[
	        { text: 'English', value: 'en' },
	        { text: 'Indonesian', value: 'id' },
	        { text: '简体中文', value: 'zh' },
	        { text: 'Português', value: 'pt' },
	        { text: 'Brasil', value: 'pt' },
	  ],
    };
  },
  created() {
	console.log('showConturyPicker',this.showConturyPicker);
    let that = this;
    var query = that.$route.query;
    if (query.type) {
      that.infoType = query.type;
    }
    if (query.pid) {
      that.pid = query.pid;
    }
    that.changIndex();
  },
  methods: {
	onConfirm(selected){
		localStorage.setItem('lang',selected.value);
		this.$i18n.locale = selected.value;
		this.showPicker =false;
	},
	onConturyConfirm(selected){
	
		this.Contury = selected.value;
		this.showConturyPicker = false;

	},
	selectLanguage(){
		
	},
    changPsw(name) {
      this[name] = !this[name];
    },
    changInfoType(type) {
	let that = this;

	 if (that.isFirstClick) {
		    Toast('Please select the language first');
		    that.isFirstClick = false; // 设置为 false 表示已显示过提示
			that.showPicker = true;
			return;
	  }

      if (that.infoType == type) {
        return;
      }
      that.infoType = type;
      that.changIndex();
      that.loginInfo = {};
      that.registerInfo = {};
      this.psw1 = true;
      this.psw2 = true;
      this.psw3 = true;
    },
    changIndex() {
      this.index = parseInt(20 * Math.random());
    },
    register() {
      let that = this;

      let info = that.registerInfo;
      console.log(info);
      if (!info.name || info.name.length < 5) {
        that.$parent.showTost(0, that.$t('login.tip6'));
        return;
      }
      if (!info.password || info.password.length < 6) {
        that.$parent.showTost(0, that.$t('login.tip5'));
        return;
      }
      if (!info.confirmPass || info.confirmPass != info.password) {
        that.$parent.showTost(0, that.$t('login.tip4'));
        return;
      }
      if (!info.realname || info.realname.length < 2) {
        that.$parent.showTost(0, that.$t('login.tip3'));
        return;
      }

      if (!info.paypassword || info.paypassword.length < 6) {
        that.$parent.showTost(0, that.$t('login.tip2'));
        return;
      }
	  info.Contury = this.Contury;
	  if (!info.Contury) {
	    that.$parent.showTost(0, that.$t('login.xzgj'));
	    return;
	  }
      let code = that.registerInfo.code;
      // if (!code) {
      //   that.$parent.showTost(0, '请输入验证码！');
      //
      //   return;
      // }
      // if (code.toUpperCase() != that.imgLis[that.index]) {
      //   that.$parent.showTost(0, '验证码错误！');
      //   that.registerInfo.code = null;
      //   that.changIndex();
      //   return;
      // }
	  
	  this.Contury
      that.$parent.showLoading();
      if (that.pid) {
        info.pid = that.pid;
      }
      that.$apiFun.register(info).then((res) => {
        that.$parent.showTost(1, res.message);
        if (res.code == 200) {
          sessionStorage.setItem("token", res.data.api_token);
          that.$store.commit("changToken");
          that.$parent.getUserInfo();
          that.$parent.openDaoTime();

          that.$parent.goNav("/");
        }
        that.$parent.hideLoading();
      });
    },
    login() {
      let that = this;
      let info = that.loginInfo;
      if (!info.name || !info.password) {
        that.$parent.showTost(0, that.$t('login.tip1'));
        return;
      }
      let code = that.loginInfo.code;
      // if (!code) {
      //   that.$parent.showTost(0, '请输入验证码！');
      //
      //   return;
      // }
      // if (code.toUpperCase() != that.imgLis[that.index]) {
      //   that.$parent.showTost(0, '验证码错误！');
      //   that.loginInfo.code = null;
      //   that.changIndex();
      //   return;
      // }

      that.$parent.showLoading();
      that.$apiFun.login(info).then((res) => {
        if (res.code !== 200) {
          that.$parent.showTost(0, res.message);
          that.$parent.hideLoading();
        }
        if (res.code === 200) {
          sessionStorage.setItem("token", res.data.api_token);
          that.$store.commit("changToken");
          that.$parent.getUserInfo();
          that.$parent.openDaoTime();

          that.$parent.goNav("/");
        }
        that.$parent.hideLoading();
      });
    },
  },
  mounted() {
    let that = this;
  },
  updated() {
    let that = this;
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
	.container {
	  position: relative;
	  width: 100%;
	  height: 100vh; /* 或者根据需要设置合适的高度 */
	}
	
	.top-right-button {
	  position: absolute;
	  top: 10px;
	  right: 10px;
	  z-index: 10; /* 确保按钮在最上层 */
	}
.form {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/static/image/entry.jpg");
  background-size: cover;

  .van-form {
    background-color: hsla(0, 0%, 85%, 0.4);
    padding: 16px;
    flex: 1;
    border-radius: 8px 5px 5px 8px;
    :deep(.van-cell) {
      background-color: transparent;

      .van-field__left-icon {
        display: flex;
        align-items: center;
      }

      .van-field__control {
        color: #ffffff;

        &::placeholder {
          color: #ffffff;
        }
      }
    }
  }

  .login_form {
    width: 90%;
    display: flex;
    align-items: center;
    margin: 0 16px;
    padding: 4px 0 0 0;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.4);

    .cell {
      padding: 14px 16px 0;
      display: flex;
      align-items: center;
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 10px;
      color: #ffffff;
      padding: 8px 16px 12px;
      span {
        padding-right: 8px;
      }
    }
  }
}
.locationToRight {
  width: 30px;
  height: 100%;
  font-size: 11px;
  color: #ffffff;
  text-align: center;

  img {
    width: 20px;
    height: 20px;
    margin-bottom: 4px;
  }
}

.title {
  text-align: center;
  font-size: 18px;
  margin: 12px 0;
  color: #ffffff;
}

.register_form {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 16px;
  background-color: hsla(0, 0%, 85%, 0.4);
  border-radius: 8px;
  .register_title {
    position: relative;
    text-align: center;

    .back {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #ffffff;

      img {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
    }
  }
  .cell {
    margin-top: 16px;
  }

}
</style>
