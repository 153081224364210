<!-- 彩票博彩页面 -->
<template>
  <div class="lottery">
    <van-nav-bar
      title="台湾宾果"
      left-arrow
      safe-area-inset-top
      z-index="2009"
      @click-left="$router.back()"
    />
    <div class="content">
      <div class="title" @click="historyShow = !historyShow">
        <div class="issue">第<span>{{nowExpectInfo.expect}}</span>期</div>
        <div class="nums">
        <div class="carBall">
			<div
			  v-for="(item, index) in nums"
			  :key="index"
			  class="pknum"
			  :class="`pk${item.trim()}`"
			></div>
          </div>
<!--          <div class="extras">
            <div v-for="item in extNumns" :key="item" class="extra">
              {{ item }}
            </div>
          </div> -->
        </div>
      </div>
      <div class="current">
        <div class="block">
          <span class="num">{{ incrementedExpect }}</span>期&nbsp;&nbsp;封盘&nbsp;<span class="num"
            >{{ fpTime }}</span
          >&nbsp;&nbsp;开奖&nbsp;<span class="num">{{ kjTime }}</span
          >&nbsp;&nbsp;余额&nbsp;<span class="num">{{parseFloat(userInfo.balance).toFixed(2)}}</span>
        </div>
      </div>
      <div v-if="historyShow" class="history">
        <div class="switch">
          <div
            class="item"
            :class="{ active: historySelected === 0 }"
            @click="historySelected = 0"
          >
            走势
          </div>
          <div
            class="item"
            :class="{ active: historySelected === 1 }"
            @click="historySelected = 1"
          >
            长龙
          </div>
        </div>
        <div class="context">
          <div v-if="historySelected === 0" class="list">
            <div v-for="(item, index) in historyList" :key="index" class="item">
              <div class="issue">
                <div><span>{{item.expect}}</span>期</div>
                <div>{{ item.opentime.split(' ')[1] }}</div>
              </div>
              <div class="result">

			<div v-for="(value, idx) in item.opencode.split(',')" :key="idx" class="pknum" :class="`pk${value.trim()}`"></div>


				
              </div>
            </div>
          </div>
         <div v-else-if="historySelected === 1" class="longDragon">
<!--            <div v-for="item in 7" :key="item" class="item">
              <div class="name">第五名</div>
              <div class="type">双</div>
              <div class="value">5期</div>
            </div> -->
          </div>
        </div>
      </div>

  <div class="msgbox" v-if="sortedMessages">
    <div v-for="(message, index) in sortedMessages" :key="index">
      <!-- 根据类型渲染不同的内容 -->
      <div v-if="message.type === 'fp'" class="msgbox-service">
        <div class="chat-time">{{ message.time }}</div>
        <div class="chat-user">{{ message.nickname }}</div>
        <div class="chat-message">{{ message.content }}</div>
      </div>

      <div v-if="message.type === 'tz'" class="msgbox-chat">
        <div class="chat-time">{{ formatDate(message.oddtime) }}</div>
        <div class="chat-user">
          <img class="avatar" src="/static/image/imageAvatar02@3x.png" alt="" />
          {{ message.username }}
        </div>
        <div class="chat-bet">
          <div class="bet-info">
            <div class="issue">{{ message.expect }}期</div>
            <div>总计：￥{{ message.amount }}</div>
          </div>
          <div class="bet-list">
            <div class="bet-detail" v-for="(item, index) in JSON.parse(message.content)" :key="index">
              <span>{{ item.remark }} {{ item.title }}</span>
              <span class="price">￥{{ item.amount }}</span>
          <!--    <span class="bet-btn">跟投</span> -->
            </div>
          </div>
        </div>
      </div>
	  
      <div v-if="message.type === 'zj'" class="msgbox-chat">
        <div class="chat-time">{{ message.time }}</div>
        <div class="chat-user">
          <img class="avatar" src="/static/image/imageAvatar02@3x.png" alt="" />
          {{ message.username }}
        </div>
        <div class="chat-bet" >
          <div class="bet-info" style="background:#a5a115">
            <div class="issue">{{ message.expect }}期</div>
            <div>盈利金额：￥{{ message.winMoney }}</div>
          </div>
         <div class="bet-list">
            <div class="bet-detail" v-for="(item, index) in JSON.parse(message.content)" :key="index">
              <span>{{ item.remark }} {{ item.title }}</span>
              <span class="price">￥{{ item.amount }}</span>
       <!--       <span class="bet-btn">跟投</span> -->
            </div>
          </div>
        </div>
      </div>
	  
	  
      <div v-if="message.type === 'orderList'" class="msgbox-check">
        <div class="check-time">{{ message.time }}</div>
        <div class="check-head">
          {{ parseInt(message.expect) +1 }}期投注详情
          <span>(总额: {{ message.totalMoney }}元)</span>
        </div>
        <div class="check-list">
          <template v-if="message.content.length > 0">
            <div v-for="(contentItem, key) in message.content" :key="key" class="check-list-row">
              <div class="user">
                <img src="/static/image/imageAvatar02@3x.png" alt="" />
                <span>{{ contentItem.username }}</span>
              </div>
              <div class="data">
				  <div v-for="(content, key) in JSON.parse(contentItem.content)" :key="key">
					{{content.remark}}/{{content.title}}{{content.amount}}
			    </div>
              </div>
            </div>
          </template>
<!--          <template v-else>
            <div class="carBalls">
              <div class="carBall">
               <div v-for="item in nums" :key="item" class="pknum" :class="`pk${item}`"></div>
              </div>
            </div>
          </template> -->
        </div>
      </div>
    </div>
  </div>

      <div class="actions" :class="{ actionShow }">
        <template v-if="actionShow">
          <div class="action" @click="clear()">
            <img src="/static/image/clean1.png" alt="" />
            <div class="name">清屏</div>
          </div>
<!--          <div class="action" @click="cancelOrder()">
            <img src="/static/image/cancel1.png" alt="" />
            <div class="name">撤单</div>
          </div> -->
        </template>
        <img
          class="add1"
          src="/static/image/Add1.svg"
          alt=""
          @click="actionShow = !actionShow"
        />
      </div>
    </div>
    <div class="bottom">
      <img
        class="keyboard"
        src="/static/image/keyboard.png"
        alt=""
        @click="show = !show"
      />
      <div class="box">
        <van-field v-model="boardValue" placeholder="请输入投注内容" />
      </div>
      <div class="btn" @click="quickBet = !quickBet">快投</div>
      <div class="btn" @click="sendOrder()">发送</div>
    </div>
    <van-popup v-model="show" position="bottom" :style="{ height: '53%' }">
      <div class="bottom" style="background-color: #ffffff">
        <img
          class="keyboard"
          src="/static/image/keyboard.png"
          alt=""
          @click="show = !show"
        />
        <div class="box">
          <van-field
            v-model="boardValue"
            placeholder="请输入投注内容"
            style="border: 1px solid #4076ee"
          />
        </div>
        <div class="btn" style="background-color: #4076ee; color: #ffffff" @click="kuaitou()">
          快投
        </div>
        <div class="btn" style="background-color: #4076ee; color: #ffffff" @click="sendOrder()">
          发送
        </div>
      </div>
      <div class="fasttext">
        <div class="key-list">
          <div class="key" @click="changeText('大')">大</div>
          <div class="key" @click="changeText('小')">小</div>
          <div class="key" @click="changeText('单')">单</div>
          <div class="key" @click="changeText('双')">双</div>
          <div class="key" @click="changeText('龙')">龙</div>
          <div class="key" @click="changeText('虎')">虎</div>
		  <div class="key" @click="changeText('和')">和</div>
        </div>
        <div class="close" @click="show = !show">关闭</div>
      </div>
      <div class="keyboard-main">
        <div class="left">
          <div class="keyboard-left">
            <div class="keys" @click="changeText('-')">-</div>
            <div class="keys" @click="changeText('和')">和</div>
            <div class="keys" @click="changeText('庄')">庄</div>
            <div class="keys" @click="changeText('闲')">闲</div>
            <div class="keys" @click="changeText('杀')">杀</div>
            <div class="keys" @click="changeText('A')">A</div>
            <div class="keys" @click="changeText('B')">B</div>
            <div class="keys" @click="changeText('C')">C</div>
            <div class="keys" @click="changeText('组')">组</div>
          </div>
        </div>
        <div class="normal">
          <div class="keys" @click="changeText('1')">1</div>
          <div class="keys" @click="changeText('2')">2</div>
          <div class="keys" @click="changeText('3')">3</div>
          <div class="keys" @click="changeText('4')">4</div>
          <div class="keys" @click="changeText('5')">5</div>
          <div class="keys" @click="changeText('6')">6</div>
          <div class="keys" @click="changeText('7')">7</div>
          <div class="keys" @click="changeText('8')">8</div>
          <div class="keys" @click="changeText('9')">9</div>
          <div class="keys" @click="changeText('.')">.</div>
          <div class="keys" @click="changeText('0')">0</div>
          <div class="keys" @click="changeText('/')">/</div>
        </div>
        <div class="right">
          <div class="keys" @click="deleteText">
            <img src="/static/image/delete1.svg" alt="" />
          </div>
          <div class="keys" @click="changeText('查')">查</div>
          <div class="keys" @click="changeText('回')">回</div>
          <div class="keys"><img src="/static/image/back1.svg" alt="" /></div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="quickBet" position="bottom" :style="{ height: '65%' }">
      <div class="quick">
        <div class="head">快速投注</div>
        <div class="body">
          <div class="left">
            <div
              v-for="(item, index) in menus"
              :key="index"
              class="menu"
              :class="{ active: index == menuActive }"
              @click="changeMenu(index)"
            >
              {{ item }}
            </div>
          </div>
          <div class="bets" >
            <div class="items">

              <div class="bet" v-if="menuActive == 0" >

              <div v-for="item in categorizedOdds['单双玩法']" :key="item.playid" :class="['item', { selected: selectedItems.some(selectedItem => selectedItem.playid === item.playid) }]" @click="toggleSelection(item)">
                  {{ item.title }} @ {{ item.rate }}
                </div>
              </div>
			  <div class="bet" v-if="menuActive == 1" >
			  
			  <div v-for="item in categorizedOdds['大小和']" :key="item.playid" :class="['item', { selected: selectedItems.some(selectedItem => selectedItem.playid === item.playid) }]" @click="toggleSelection(item)">
			      {{ item.title }} @ {{ item.rate }}
			    </div>
			  </div>
			  
			  <div class="bet" v-if="menuActive == 2" >
			  
			  <div v-for="item in categorizedOdds['龙虎和']" :key="item.playid" :class="['item', { selected: selectedItems.some(selectedItem => selectedItem.playid === item.playid) }]" @click="toggleSelection(item)">
			      {{ item.title }} @ {{ item.rate }}
			    </div>
			  </div>
			  
			  <div class="bet" v-if="menuActive == 3" >
			  
			  <div v-for="item in categorizedOdds['球位玩法']" :key="item.playid" :class="['item', { selected: selectedItems.some(selectedItem => selectedItem.playid === item.playid) }]" @click="toggleSelection(item)">
			      {{ item.title }} @ {{ item.rate }}
			    </div>
			  </div>
			  <div class="bet" v-if="menuActive == 4" >
			  
			  <div v-for="item in categorizedOdds['组合玩法']" :key="item.playid" :class="['item', { selected: selectedItems.some(selectedItem => selectedItem.playid === item.playid) }]" @click="toggleSelection(item)">
			      {{ item.title }} @ {{ item.rate }}
			    </div>
			  </div>
			  <div class="bet" v-if="menuActive == 5" >
			  
			  <div v-for="item in categorizedOdds['单号玩法']" :key="item.playid" :class="['item', { selected: selectedItems.some(selectedItem => selectedItem.playid === item.playid) }]" @click="toggleSelection(item)">
			      {{ item.title }} @ {{ item.rate }}
			    </div>
			  </div>
			  <div class="bet" v-if="menuActive == 6" >
			  
			  <div v-for="item in categorizedOdds['连码下注']" :key="item.playid" :class="['item', { selected: selectedItems.some(selectedItem => selectedItem.playid === item.playid) }]" @click="toggleSelection(item)">
			      {{ item.title }} @ {{ item.rate }}
			    </div>
			  </div>
            </div>
<!--            <div class="items">
              <div class="title">双面</div>
              <div class="bet">
                <div v-for="item in 6" :key="item" class="item">
                  {{ item }} @ 9.801
                </div>
              </div>
            </div>
            <div class="items">
              <div class="title">组合</div>
              <div class="bet">
                <div v-for="item in 4" :key="item" class="item">
                  {{ item }} @ 9.801
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="footer">
          <div class="group">
            <div class="btn" @click="deleteAll()">清空</div>
            <div class="text">
              共
              <span class="num">{{selectedItems.length || 0 }}</span>
              注&nbsp;&nbsp;&nbsp;每注:
              <span class="num">{{ quickBetValue}}</span>
              元&nbsp;&nbsp;&nbsp;共
              <span class="num">{{ quickBetValue*selectedItems.length || 0 }}</span>
              元
            </div>
            <div class="btn" @click="confirmOrder()">投注</div>
          </div>
          <div class="panel">
            <div class="left">
              <div class="prices">
                <div class="price cyan" @click="changeQuickBetValue(10)">
                  10
                </div>
                <div class="price purple" @click="changeQuickBetValue(20)">
                  20
                </div>
                <div class="price yellow" @click="changeQuickBetValue(50)">
                  50
                </div>
                <div class="price red" @click="changeQuickBetValue(100)">
                  100
                </div>
                <div class="price fuchsia" @click="changeQuickBetValue(500)">
                  500
                </div>
                <div class="price green" @click="changeQuickBetValue(1000)">
                  1千
                </div>
                <div class="price blue" @click="changeQuickBetValue(5000)">
                  5千
                </div>
              </div>
            </div>
            <div class="box">
              <van-field v-model="totalBetValue" placeholder="金额" />
            </div>
          </div>
        </div>
      </div>
    </van-popup>

	<van-dialog v-model="confirmShow" title="投注确认" show-cancel-button confirmButtonColor="#4076ee" cancel-button-color="#4076ee"	theme="round-button" @confirm="onConfirm()">
		<div class="bet-view">
		  <!-- 投注期号 -->
		  <div class="issue">
			<span>投注期号: 第
			  <span class="number">51135737</span>
			  期
			</span>
		  </div>

		  <!-- 投注列表 -->
		  <div class="bet-list">
			<div style="max-height: 150px; overflow-y: auto;">
			  <div class="uni-scroll-view">
				<div class="uni-scroll-view-content">
				  <!-- 投注详情项 -->
				  <div class="bet-detail" v-for="(item,index) in this.selectedItems" style="display: flex;flex-direction: row;justify-content: space-around;    align-items: center;">
					  <div class="newcontent">{{item.remark}}</div>
					  <div class="newcontent">{{item.title}}</div>
					  <div class="odds" style="color: #9c9c9c;">@<span class="number">{{item.rate}}</span></div>
					  <input maxlength="140" type="number" class="price input-number" :value="item.amount || 0" @input="updateQuickBetValue($event, index)" style="width: 20%;">
					  <van-icon name="delete-o" size="16px" @click="deleteOneOrder(index)" />
				  </div>

				</div>
			  </div>
			</div>
		  </div>

		  <!-- 投注信息 -->
		  <div class="bet-info">
			<span>
			  总注数:
			  <span class="number" style="padding-right: 10px;">{{selectedItems.length || 0 }}</span>
			  总金额:
			  <span class="number" >{{ totalAmount }}元</span>
			</span>
			<span>
			  当前余额为
			  <span class="number">{{userInfo.balance}}元</span>
			</span>
		  </div>
		</div>

	</van-dialog>
  </div>

</template>

<script>
import ws from '@/http/websocket';
import { Toast } from 'vant'

export default {
  name: "LotteryTicket",
  data() {
    return {
      show: false,
      actionShow: false,
      boardValue: "",
      nums: [],
      extNumns: ["18", "大", "小", "单", "双", "龙", "虎", "杀"],
      historySelected: 0,
      historyShow: false,
      quickBet: false,
      quickBetValue: 0,
	  historyList:[],
	  userInfo:[],
	  nowExpectInfo:[],
	  odds:[],
	  fpTime:null,
	  kjTime:null,
	  fp:null,
	  closingTime:null,
	  openingTime:null,
	  closingTimeTimestamp:null,
	  openingTimeTimestamp:null,
	  totalBetValue:0,
	  showVisible: false, // 提示框显隐
	  toastMsg: '',
	  totalAmount:0,
      menus: [
      ],
	  confirmShow:false,
      menuActive: 0,
	  categorizedOdds:[],
	  item: null,
	  selectedItems: [],
      chatList: {
        fp: [],
        tz: [],
        orderList: [],
		zj:[]
      },
    };
  },
  computed: {
  sortedMessages() {
    // 确保 chatList 和其属性是对象和数组
    const chatList = this.chatList || {};
    const fp = Array.isArray(chatList.fp) ? chatList.fp : [];
    const tz = Array.isArray(chatList.tz) ? chatList.tz : [];
    const orderList = Array.isArray(chatList.orderList) ? chatList.orderList : [];
    const zj = Array.isArray(chatList.zj) ? chatList.zj : [];

    // 合并所有消息并确保每个 item 具有有效的 time 属性
    const combinedMessages = [
      ...fp.map(item => ({
        ...item,
        type: 'fp',
        time: item.time ? new Date(item.time) : new Date(0) // 默认时间为1970年
      })),
      ...tz.map(item => ({
        ...item,
        type: 'tz',
        time: item.time ? new Date(item.time) : new Date(0)
      })),
      ...orderList.map(item => ({
        ...item,
        type: 'orderList',
        time: item.time ? new Date(item.time) : new Date(0)
      })),
      ...zj.map(item => ({
        ...item,
        type: 'zj',
        time: item.time ? new Date(item.time) : new Date(0)
      }))
    ];

    // 按时间排序
    combinedMessages.sort((a, b) => a.time - b.time);

    // 输出日志
    console.log('combinedMessages', combinedMessages);

    return combinedMessages;
  },
  incrementedExpect() {
     return parseInt(this.nowExpectInfo.expect) + 1;
  }
  },

  methods: {
	  
	fetchData(){
	
		let that = this;
		let params = {
			id: 1
		};
		that.$parent.showLoading();
		that.$apiFun.getGameInfo(params).then((res) => {
		  if (res.code !== 200) {
		    that.$parent.showTost(0, res.message);
		    that.$parent.hideLoading();
		  }
		  if (res.code === 200) {
			this.historyList = res.data.history;
			this.nowExpectInfo = res.data.nowExpectInfo;
			this.nums = res.data.nowExpectInfo.opencode.split(',');
			this.fp = res.data.stop_time;
			let kjTime = res.data.nowExpectInfo.opentime;
			let addTime = res.data.nowExpectInfo.addtime;
			let date = new Date(kjTime);
			let addDate = new Date(addTime);
		   this.openingTimeTimestamp = Math.floor(date.getTime() / 1000) + 300; // 开奖时间戳，取整
		   this.closingTimeTimestamp = Math.floor(date.getTime() / 1000) + 300 - this.fp;
			this.odds = res.data.odds;
			// 提取 remark 并去重
			this.menus = [...new Set(this.odds.map(item => item.remark))];
			// 分类函数
			this.categorizedOdds = res.data.odds.reduce((acc, item) => {
			  if (!acc[item.remark]) {
			    acc[item.remark] = [];
			  }
			  acc[item.remark].push(item);
			  return acc;
			}, {});
			console.log(this.categorizedOdds);
	
	
		  }
		  that.$parent.hideLoading();
		});
	},
	updateTimes() {
	      const now = Math.floor(new Date().getTime() / 1000);
	      this.fpTime = this.formatTime(this.closingTimeTimestamp - now);
	      this.kjTime = this.formatTime(this.openingTimeTimestamp - now);
	      if (this.closingTimeTimestamp <= now) {
	        this.fpTime = '00:00'; // 封盘时间已过
	      }
	      if (this.openingTimeTimestamp <= now) {
	        this.kjTime = '开奖中'; // 开奖时间已过
	      }
	},
    changeText(val) {
      this.boardValue = `${this.boardValue}${val}`;
    },
    deleteText() {
      if (this.boardValue.length == 0) return;
      this.boardValue = this.boardValue.slice(0, -1);
    },
    changeQuickBetValue(val) {
	  this.totalBetValue = this.selectedItems.length * val;
	 console.log(val);
	  this.selectedItems.forEach(item => {
	          item.amount = val;
	        });
	  this.totalAmount = this.selectedItems.reduce((total, item) => total + (item.amount || 0), 0);

      return (this.quickBetValue = val);
    },
    changeMenu(val) {
		console.log(val);
      this.menuActive = val;
    },
	formatTime(seconds) {
	      const minutes = Math.floor(seconds / 60);
	      const remainingSeconds = seconds % 60;
	      return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
	},
	calculateRemainingTime(targetTime) {
	      const now = new Date();
	      const remainingTime = targetTime - now;
	      return Math.max(remainingTime, 0); // 确保时间不为负值
	},
	toggleSelection(item) {
	      const index = this.selectedItems.findIndex(selectedItem => selectedItem.playid === item.playid);

		  item.amount = this.quickBetValue;

	      if (index > -1) {
	        // If already selected, remove from the array
	        this.selectedItems.splice(index, 1);
	      } else {
	        // If not selected, add to the array
	        this.selectedItems.push(item);
	      }
		  this.totalBetValue = this.selectedItems.length * this.quickBetValue;
		  console.log(this.selectedItems);
	},
	deleteAll(){
		this.selectedItems=[];
	},
	confirmOrder(){
		this.confirmShow =true;

	},
	updateQuickBetValue(event, index) {
		
		this.selectedItems[index].amount = parseFloat(event.target.value) || 0;
		this.totalAmount = this.selectedItems.reduce((total, item) => total + (item.amount || 0), 0);

	},
    deleteOneOrder(index) {

      this.selectedItems.splice(index, 1);
    },
	
    onConfirm() {
     
		let that = this;
		let params = {
			totalAmount:this.totalAmount,
			userInfo: this.userInfo,
			data: this.selectedItems,
			typeid:1,
			expect:this.nowExpectInfo.expect
		};
		that.$parent.showLoading();
		that.$apiFun.createOrder(params).then((res) => {
		  if (res.code != 200) {
			  that.$parent.hideLoading();
			  this.quickBet =false;
		   // that.$parent.showTost(0,res.message);
		   Toast(res.message);
			return;
		  }
		  that.$parent.hideLoading();
		  that.quickBet =false;
		  // that.$parent.showTost(1,'投注成功');
		  Toast('投注成功');

		  that.selectedItems=[];
		  that.userInfo.balance = res.data.data.balance;
	

		}); 

    },
	clear(){
		this.chatList=[];
		localStorage.setItem('chatList','');
	},
    formatDate(timestamp) {
		  // 如果时间戳是秒，则需要转换为毫秒
		  if (timestamp.toString().length === 10) {
			timestamp *= 1000;
		  }
		  const date = new Date(timestamp);
		  
		  // 格式化日期和时间
		  const year = date.getFullYear();
		  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始
		  const day = String(date.getDate()).padStart(2, '0');
		  const hours = String(date.getHours()).padStart(2, '0');
		  const minutes = String(date.getMinutes()).padStart(2, '0');
		  const seconds = String(date.getSeconds()).padStart(2, '0');
		  
		  // 返回格式化后的日期时间字符串
		  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		},
	kuaitou(){
		console.log('点击');
		this.show = false;
		this.quickBet =true;
	},
	sendOrder(){
		let orderContent=this.boardValue;
		
		Toast('投注格式错误，请使用快投');
		// this.$parent.showTost(0, '投注格式错误，请使用快投');
		
	},
  },
  created() {
	  // Toast('提示内容');

	  this.fetchData();
	  this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
	  let res = JSON.parse(localStorage.getItem('chatList'));
	  this.chatList = res;
	  this.updateTimes();
	  this.timer = setInterval(() => {
		  this.updateTimes();
      }, 1000);


	  
  },

  mounted() {
	  
	 ws.connect();
	 // ws.socket.onopen = () => {
	 //  let userInfo = localStorage.getItem('userInfo');
	 //  userInfo = JSON.parse(userInfo);
	 //  let pingMessage = JSON.stringify({
		//       userId:userInfo.uid,
		// 	  customId:userInfo.uid,
	 //          type: 'init'
	 //      });

	 //   ws.sendMessage(pingMessage);
	 // };
	 ws.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
	  //开奖了

	  if(data.code == 209 ){
		  const now = Math.floor(new Date().getTime() / 1000);
		  this.nowExpectInfo.expect = data.data.arr.expect;
		  this.nums = data.data.arr.opencode.split(',');
		  let addTime = data.data.arr.addtime;
		  let kjTime = data.data.arr.opentime;
		  let addDate = new Date(addTime);
		  let date = new Date(kjTime);

		  this.openingTimeTimestamp = Math.floor(date.getTime() / 1000) + 300; // 开奖时间戳，取整
		  this.closingTimeTimestamp = Math.floor(date.getTime() / 1000) + 300 - this.fp; // 封盘时间戳，取整
		  this.fpTime = this.formatTime(this.closingTimeTimestamp - now);
		  this.kjTime = this.formatTime(this.openingTimeTimestamp - now);
		  let newData = {
		    expect: data.data.arr.expect,
		    opentime: data.data.arr.opentime,
		    opencode: data.data.arr.opencode
		  };

		  this.historyList = [newData, ...this.historyList];
		  // 确保 chatList 已初始化
		  if (!this.chatList) {
		      this.chatList = { fp: [] };
		  } else if (!Array.isArray(this.chatList.fp)) {
		      this.chatList.fp = [];
		  }
		  //添加系统消息
		  this.chatList.fp.unshift(data.data);
		  localStorage.setItem('chatList',JSON.stringify(this.chatList));
	  }
	  if(data.code == 201 ){

		// 确保 chatList 已初始化
		if (!this.chatList) {
		    this.chatList = { fp: [] };
		} else if (!Array.isArray(this.chatList.fp)) {
		    this.chatList.fp = [];
		}
			//添加系统消息
		  this.chatList.fp.unshift(data.data);
		  localStorage.setItem('chatList',JSON.stringify(this.chatList));
		  console.log(this.chatList);
	  }
	  if(data.code == 202 ){
			
	  		// 确保 chatList 已初始化
	  		if (!this.chatList) {
	  		    this.chatList = { tz: [] };
	  		} else if (!Array.isArray(this.chatList.tz)) {
	  		    this.chatList.tz = [];
	  		}
	  		  this.chatList.tz.unshift(data.data);
	  		  localStorage.setItem('chatList',JSON.stringify(this.chatList));
	  		  console.log(this.chatList);
	  }
	  if(data.code == 203 ){
			console.log('封盘推送订单列表');
	  		if (!this.chatList) {
	  		    this.chatList = { orderList: [] };
	  		} else if (!Array.isArray(this.chatList.orderList)) {
	  		    this.chatList.orderList = [];
	  		}
	  		  this.chatList.orderList.unshift(data.data);
	  		  localStorage.setItem('chatList',JSON.stringify(this.chatList));
	  		  console.log(this.chatList);
	  }
	  if(data.code == 205 ){
	  			console.log('推送玩家中奖信息');
	  		if (!this.chatList) {
	  		    this.chatList = { zj: [] };
	  		} else if (!Array.isArray(this.chatList.zj)) {
	  		    this.chatList.zj = [];
	  		}
	  		  this.chatList.zj.unshift(data.data);
	  		  localStorage.setItem('chatList',JSON.stringify(this.chatList));
	  		  console.log(this.chatList);
	  }
    };
  },
  updated() {},
  beforeDestroy() {
	      if (this.fpTime) clearInterval(this.fpTime);
	      if (this.kjTime) clearInterval(this.kjTime);
  },
  
};
</script>

<style lang="scss" scoped>
.lottery {
  width: 100%;
  .van-nav-bar {
    background-color: #edf2fd;
  }

  .content {
    position: relative;
    overflow-y: auto;
    width: 100%;
    background-color: #f8f8f8;
    height: calc(100vh - 50px - 51px);

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      padding: 5px;
      height: 50px;

      .issue {
        font-size: 16px;
        font-weight: 700;
        span {
          color: #4076ee;
        }
      }

      .nums {
        display: block;
        margin-left: 10px;
        font-size: 14px;

        .carBall {
          display: flex;
        }

        .extras {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          text-align: center;

          .extra {
            padding-inline: 5px;
          }
        }
      }
    }

    .current {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 4px;
      background: #fff;
      font-size: 14px;
      padding: 10px 5px;

      .block {
        margin-inline: 5px;

        .num {
          margin-left: 5px;
          color: #ffa82c;
        }
      }
    }

    .history {
      position: absolute;
      right: 0;
      left: 0;
      padding-top: 5px;
      background: #fff;
      font-size: 14px;
      box-shadow: 0 -20px 30px -30px inset grey;
      .switch {
        display: flex;
        justify-content: space-around;
        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid #4076ee;
          height: 25px;
        }

        .active {
          color: #4076ee;
          border-top: 1px solid #4076ee;
          border-left: 1px solid #4076ee;
          border-right: 1px solid #4076ee;
          border-bottom: 0;
          border-radius: 10px 10px 0 0;
        }
      }

      .context {
        overflow: hidden auto;

        .list {
          height: 250px;
          padding: 5px 0;
          display: flex;
          flex-direction: column;

          .item {
            padding: 5px 0;
            display: flex;
            align-items: center;

            &:not(:last-child) {
              border-bottom: 2px solid #f8f8f8;
            }
            .issue {
              font-size: 12px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 200px;
              span {
                color: #4076ee;
              }
            }

            .result {
              display: flex;
            }
          }
        }

        .longDragon {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px;
          font-size: 14px;

          .item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            box-sizing: border-box;
            border-right: 2px solid #f8f8f8;
            border-bottom: 2px solid #f8f8f8;
            padding: 7px;

            .name {
              min-width: 50px;
              text-align: center;
              margin-right: 5px;
            }
            .type {
              display: flex;
              justify-content: center;
              align-items: center;
              background: #4076ee;
              border-radius: 3px;
              color: #fff;
              width: 22px;
              height: 22px;
            }
            .value {
              margin-left: 20px;
              color: #ffa82c;
              font-weight: 700;
            }
          }
        }
      }
    }

    .msgbox {
      height: 620px;
      overflow: hidden auto;
      padding-bottom: 55px;

      .msgbox-service {
        display: flex;
        flex-direction: column;
        padding: 25px 15px;
        padding-bottom: 5px;
        .chat-time {
          align-self: center;
          text-align: center;
          font-size: 11px;
          font-weight: 500;
          color: #a0a0a0;
          margin-bottom: 15px;
        }
        .chat-user {
          font-size: 11px;
          font-weight: 500;
          color: #a0a0a0;
        }
        .chat-message {
          margin-top: 10px;
          max-width: 230px;
          padding: 10px;
          background: hsla(0, 0%, 80.8%, 0.4);
          border-radius: 5px;
          word-wrap: break-word;
          font-size: 11px;
          font-weight: 500;
          color: #a0a0a0;
        }
      }

      .msgbox-chat {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #fff;
        padding: 25px 15px;
        padding-bottom: 5px;
        .chat-time {
          align-self: center;
          text-align: center;
          font-size: 11px;
          font-weight: 500;
          color: #a0a0a0;
          margin-bottom: 15px;
        }
        .chat-user {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 12px;
          font-weight: 700;
          color: #222;
          .avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
        .chat-bet {
          margin-top: 10px;
          background-color: #fff;
          width: 250px;
          border-radius: 5px;
          .bet-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 6px 10px;
            box-sizing: border-box;
            font-size: 12px;
            font-weight: 500;
            width: 100%;
            background: #4076ee;
            border-radius: 5px 5px 0 0;

            .issue {
              display: flex;
              align-items: center;
            }
          }

          .bet-list {
            padding: 0 10px;
            .bet-detail {
              padding: 10px 0;
              font-size: 11px;
              font-weight: 500;
              color: #a0a0a0;
              border-bottom: 1px solid #e8e8e8;
              .price {
                margin-left: 10px;
              }

              .bet-btn {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                float: right;
                width: 45px;
                height: 19px;
                font-size: 11px;
                font-weight: 500;
                color: #fff;
                background: linear-gradient(0deg, #4c9cff, #5449df);
                border-radius: 5px;

                &:active {
                  background: linear-gradient(0deg, #5449df, #4c9cff);
                }
              }
            }
          }
          .bet-drop {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #a0a0a0;
            height: 20px;
            font-size: 12px;
          }
        }
      }

      .msgbox-check {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
        margin-top: 10px;
        padding: 25px 15px;
        padding-bottom: 5px;

        .check-time {
          align-self: center;
          text-align: center;
          font-size: 11px;
          font-weight: 500;
          color: #a0a0a0;
          margin-bottom: 15px;
        }

        .check-head {
          padding: 10px;
          background: linear-gradient(90deg, #1041e3, #6399fb);
          box-shadow: 1px 3px 10px 0 rgb(48 68 222 / 68%);
          color: #fff;
          text-align: center;
          border-radius: 7px 7px 0 0;
          font-size: 16px;

          span {
            font-size: 10px;
          }
        }

        .check-list {
          display: flex;
          flex-direction: column;
          border-radius: 0 0 7px 7px;
          background-color: #fff;
          padding: 10px;
          padding-top: 5px;
          .check-list-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            &:not(:last-child) {
              border-bottom: 1px solid #e8e8e8;
            }
            .user {
              flex: 0 0 auto;
              display: flex;
              align-items: center;
              font-size: 12px;
              font-weight: 700;
              color: #222;
              margin-right: 15px;
              img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 5px;
              }
            }

            .data {
              font-size: 12px;
              color: #222;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          .carBalls {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
          }

          .carBall {
            display: flex;
          }
        }
      }
    }

    .actions {
      position: fixed;
      right: 0;
      bottom: 12%;
      margin: 7px 10px;

      img {
        width: 38px;
        height: 38px;
      }

      .add1 {
        margin: 7px 12px 0;
        transition: all 0.4s;
        transform: rotate(0deg);
      }

      .action {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 7px 0;
        font-size: 12px;
        font-weight: 500;
        color: #222;
      }
    }

    .actionShow {
      .add1 {
        transform: rotate(45deg);
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    background-color: #edf2fd;
    padding: 5px 15px;

    .box {
      flex: 0.94;
      .van-cell {
        width: 100%;
        border-radius: 5px;
        padding: 3px 0 3px 10px;
      }
    }

    .keyboard {
      width: 36px;
      height: 36px;
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 30px;
      font-size: 12px;
      font-weight: 500;
      background-color: #fff;
      color: #4076ee;
      border-radius: 5px;
    }
  }

  .fasttext {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 500;
    color: #222;
    border-top: 1px solid hsla(0, 0%, 40%, 0.2);
    .key-list {
      display: flex;
      align-items: center;
      .key {
        font-size: 15px;
        font-weight: 500;
        color: #222;
        border-right: 1px solid hsla(0, 0%, 40%, 0.4);
        padding: 3px 15px;
        margin: 10px 0;

        &:last-child {
          border-right: none;
        }

        &:active {
          opacity: 0.6;
        }
      }
    }
    .close {
      display: flex;
      width: 50px;
      height: 47px;
      font-size: 12px;
      font-weight: 500;
      color: #222;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      box-shadow: 0.5px 5px 10px 0 #dbd8d9;
    }
  }
  .keyboard-main {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px 20px;
    background-color: #e8e8e8;

    .left {
      overflow: hidden auto;
      height: 220px;
      border-radius: 5px;
      .keyboard-left {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background-color: #fff;
        border-radius: 5px;
        width: 65px;
        margin: 2px;

        .keys {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 65px;
          height: 50px;
          font-size: 16px;
          font-weight: 500;
          color: #222;
          margin: 0;
          margin-top: 2px;
          margin-bottom: 2px;
          border-bottom: 1px solid hsla(0, 0%, 40%, 0.2);

          &:last-child {
            border-bottom: none;
          }

          &:active {
            opacity: 0.6;
          }
        }
      }
    }
    .normal {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      flex: 1;

      .keys {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 65px;
        height: 50px;
        font-size: 16px;
        font-weight: 500;
        color: #222;
        background-color: #fff;
        border-radius: 5px;
        margin: 2px;
        flex: 1 1 30%;

        &:active {
          opacity: 0.6;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 69px;
      .keys {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 65px;
        height: 50px;
        font-size: 16px;
        font-weight: 500;
        color: #222;
        background-color: #fff;
        border-radius: 5px;
        margin: 2px;
        flex: 1 1 30%;
        img {
          width: 22px;
          height: 18px;
        }

        &:active {
          opacity: 0.6;
        }
      }
    }
  }

  .quick {
    width: 100%;
    background-color: #fff;
    .head {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: 40px;
      background: linear-gradient(180deg, #105eeceb, #2c95d4cf);
      color: #fff;
      font-size: 17px;
      font-weight: 700;
    }

    .body {
      display: flex;
      height: 280px;
      .left {
        width: 99px;
        height: 100%;
        overflow: hidden auto;
        background: #f7f7f7;
        .menu {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px 0;
          color: #222;
          font-size: 14px;
          &:not(:last-child) {
            border-bottom: 1px solid #e5e5e5;
          }
        }

        .active {
          color: #4076ee;
          font-weight: 700;
          background: #fff;
          border-bottom: 3px solid #4076ee;
        }
      }

      .bets {
        flex: 1;
        height: 100%;
        overflow: hidden auto;
        .items {
          display: flex;
          flex-direction: column;
          align-items: center;

          .title {
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url("/static/image/bettitle1.png");
            background-size: 100%;
            width: 60px;
            height: 30px;
            font-size: 14px;
            font-weight: 400;
            font-family: "ZhenyanGB";
            color: #4076ee;
            margin-bottom: 10px;
          }

          .bet {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            .item {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 160px;
              height: 25px;
              color: #a0a0a0;
              font-size: 12px;
              font-weight: 400;
              border: 1px solid #a0a0a0;
              border-radius: 5px;
              margin: 5px 25px;
            }

            .active {
              border: 1px solid #4076ee;
              color: #fff;
              background-color: #4076ee;
            }
          }
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      padding-bottom: 22px;
      .group {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        padding: 10px 15px;

        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 45px;
          height: 30px;
          font-size: 12px;
          font-weight: 500;
          color: #fff;
          background: #4076ee;
          border-radius: 5px;
        }

        .text {
          width: 225px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #f7f7f7;
          border-radius: 5px;
          font-size: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .num {
            color: #4076ee;
            font-weight: 700;
          }
        }
      }

      .panel {
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        .left {
          width: 255px;
          overflow: auto hidden;

          .prices {
            display: flex;
            flex-direction: row;
            font-size: 13px;
            font-weight: 800;
            color: #fff;
            text-shadow: 2px 2px 7px #000;

            .price {
              flex: 0 0 auto;
              margin: 0 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 35px;
              height: 35px;
              background: url("/static/image/price1.png") no-repeat;
              background-size: 280px;
            }
            .cyan {
              background-position-x: 0;
            }
            .purple {
              background-position-x: -41px;
            }
            .yellow {
              background-position-x: -82px;
            }
            .red {
              background-position-x: -123px;
            }
            .fuchsia {
              background-position-x: -164px;
            }
            .green {
              background-position-x: -205px;
            }
            .blue {
              background-position-x: -245px;
            }
          }
        }

        .box {
          display: flex;
          align-items: center;
          margin-left: 6px;
          .van-cell {
            width: 90px;
            border-radius: 5px;
            padding: 3px 5px;
            border: 1px solid #4076ee;
          }
        }
      }
    }
  }

  .pknum {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1px;
    font-size: 12px;
    font-weight: 900;
    color: #fff;
    background: url("/static/image/pknum1.png") no-repeat;
    background-size: 197px;
  }
  .pk1 {
    background-position: -118px 0.5px;
  }
  .pk2 {
    background-position: -19px 0.5px;
  }
  .pk3 {
    background-position: -39px 0.5px;
  }
  .pk4 {
    background-position: -59px 0.5px;
  }
  .pk5 {
    background-position: -138px 0.5px;
  }
  .pk6 {
    background-position: 0 0.5px;
  }
  .pk7 {
    background-position: -99px 0.5px;
  }
  .pk8 {
    background-position: -79px 0.5px;
  }

  .pk9 {
    background-position: -158px 0.5px;
  }
  .pk0 {
    background-position: -179px 0.5px;
  }
  .item.selected {
    background-color: #2460cf; /* Highlight color */
    color: white;
    /* Add other selected item styles here */
  }
  .number{
	    color: rgb(64, 118, 238);
		font-weight: 700;
  }
  .bet-view{
	      display: flex;
	      flex-direction: column;
		  .issue{
			      margin: 25px 0;
			      text-align: center;
			      font-size: 12px;
			      font-weight: 500;
			      color: #222;
		  }
		 .bet-list{
			     display: flex;
			     flex-direction: column;
			     padding: 0 30px;
			     font-size: 13px;
			     font-weight: 500;
		 }
		 .bet-info{
			     display: flex;
			     flex-direction: column;
			     justify-content: center;
			     align-items: center;
			     font-size: 13px;
			     font-weight: 500;
			     color: #404040;
			     padding-top: 25px;
			     padding-bottom: 17px;
		 }
		 .btn-group{
			     width: 100%;
			     margin-left: 0;
		 }
  }
}
</style>
