<template>
  <div>
    <van-tabbar v-model="active" z-index="500" route @change="onChange" class="bg">
      <van-tabbar-item icon="/static/image/dw/mobile-login-icon.png" to="/home">
        {{ $t('foot.home') }}
      </van-tabbar-item>
      <van-tabbar-item icon="/static/image/dw/mobile-daftar-icon.png" to="/recharge">
        {{ $t('foot.deposit') }}
      </van-tabbar-item>
      <van-tabbar-item
        icon="/static/image/dw/mobile-home-main-icon.png"
        class="main"
      ></van-tabbar-item>
      <van-tabbar-item icon="/static/image/dw/mobile-promo-icon.png" @click="goTo">
        {{ $t('foot.customer') }}
      </van-tabbar-item>
      <van-tabbar-item icon="/static/image/dw/mobile-chat-icon.png" to="/mine">
        {{ $t('foot.my') }}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
	  kf_url:null
    };
  },
  created() {

    if (sessionStorage.getItem("token")) {
      this.getUserInfo();
    }
   },
  methods: {
    onChange(index) {
    },
	goTo(){
		window.location.href = this.kf_url;
	},
	getUserInfo() {
	  let that = this;
	  that.$apiFun.post("/api/user", {}).then((res) => {
	    if (res.code === 200) {
	      let userInfo = res.data;
		  that.kf_url = userInfo.kf_url;


	    }
	  });
	}
  },
};
</script>
<style lang="scss" scoped>
	.bg{
		background-color: #0d0d0d;
	}
	.van-tabbar-item--active{
		background-color: #0d0d0d;
	}
	.main{
		width: 50px;
		.van-tabbar-item__icon{
			width: 50px;
			.van-icon img{

					width: 50px;
					height: 50px;
				
			}
		}
	}
	.van-icon__image{
					width: 50px;
					height: 50px;
	}
	.van-tabbar{
		    width: 100%;
		    height: 60px;
	}
::v-deep( .main .van-icon img) {
  width: 40px;
  height: 40px;
  animation: swingRotateAndZoom 1.5s infinite ease-in-out; /* 应用综合动画 */

}


/* 定义综合动画 */
@keyframes swingRotateAndZoom {
  0%, 100% {
    transform: rotate(0deg) scale(1); /* 初始和结束位置，不旋转，大小正常 */
  }
  25% {
    transform: rotate(-10deg) scale(1.1); /* 向左旋转 10 度，同时放大 10% */
  }
  50% {
    transform: rotate(0deg) scale(1); /* 回到正常大小 */
  }
  75% {
    transform: rotate(10deg) scale(1.1); /* 向右旋转 10 度，同时放大 10% */
  }
}
	</style>